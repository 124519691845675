import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  data() {
    return {
      courseId: '',  
      
    };
  },
  actions: {
    setCourse(ccourseId) {
      //console.log("incoming course "+ ccourseId);
      this.courseId=  ccourseId
    },
    fetchAllParticipants(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lms/admin/course/getallSubscribers', { roleid: 7 })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchParticipantsByCourse(ctx,  id ) {
      //console.log("  course subscribers Fetching --" + id);
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursesubscribers/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
   
    fetchInstructer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getinstructordetail/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCoursesbyInstructers(ctx,  { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/lms/admin/course/getcoursesbyinstructer/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInstructerStatus(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/updateinstructerstatus', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteInstructer(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/deleteinstructer', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateInstructorphoto(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/updateinstructorphoto', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInstructor(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lms/admin/course/createinstructor', { user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    


  },
}
